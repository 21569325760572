import Container from "../components/container";
import Layout from "../components/layout";
import MoreStories from "../components/more-stories";
import Header from "../components/header";
import React from 'react';
import {graphql} from "gatsby";

const Category = (props) => {
    const articles = props.data.allDatoCmsFcaArticle?.nodes;
    return (
        <>
            <Layout>
                <Container>
                    <Header category={props.pageContext.category}/>
                    <div className={"lg:mx-20"}>
                        {articles && articles.length && <MoreStories posts={articles}/>}
                    </div>
                </Container>
            </Layout>
        </>
    );
}

export const query = graphql`
    query ArticlesCategoryWise($category: String) {
        allDatoCmsFcaArticle(
            filter: {category: {slug: {eq: $category}}}
            sort: {order: DESC, fields: publishingDate}
        ) {
            nodes {
                title
                content
                slug
                category {
                    slug
                    title
                }
                publishingDate
                firmName
                frn
                documentUrl
                subTitle
            }
        }
    }
`

export default Category;
