import PostPreview from '../components/post-preview'
import React from 'react';

export default function MoreStories({posts}) {
    return (
        <section>
            <div>
                {posts.map(post => {
                        return <PostPreview
                            key={post.slug}
                            title={post.title}
                            date={post.publishingDate}
                            slug={post.slug}
                            firmName={post.firmName}
                            category={post.category.slug}
                        />
                    }
                )}
            </div>
        </section>
    )
}
